.App {
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: "Open Sans", sans-serif;
}
.titleText {
  font-size: 38px;
}

@media screen and (min-width: 1025px) {
  .contentContainer {
    margin: 15px;
    display: grid;
    grid-template-columns: 766px auto;
    max-width: 90vw;
    overflow: hidden;
    border-style: solid;
    height: 100%;
  }

  .infoContainer {
    padding: 15px 15px 15px 25px;
    background: white;
    position: static;
    z-index: 15;
    text-align: center;
  }
  .image {
    height: 100%;
    width: 100%;
    max-width: 766px;
    position: relative;
  }

  .infoLogo {
    max-width: 75%;
    display: flex;
    margin: auto;
  }

  .imageContainer {
    max-height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    max-width: 766px;
  }
  .titleText {
    font-size: 38px;
  }
  .imageMobile {
    display: none;
  }
}

@media only screen and (max-width: 1024px) {
  .contentContainer {
    margin: 15px;
    display: grid;
    grid-template-columns: 100%;
    max-width: 90vw;
    height: fit-content;
    overflow: hidden;
    border-style: solid;
  }
  .titleText {
    font-size: 28px;
  }
  .image {
    display: none;
  }
  .infoContainer {
    padding: 15px 15px 15px 25px;
    background: white;
    position: static;
    z-index: 15;
    text-align: center;
  }

  .infoLogo {
    max-width: 75%;
    display: flex;
    margin: auto;
  }
  .infoImage {
    width: 50px;
    height: auto;
  }
  .imageMobile {
    width: 100%;
    max-width: 400px;
    height: auto;
    margin-top: 16px;
  }
}

.smIcon__link {
  color: black;
}

.smIcon__icon {
  height: 2rem;
  width: 2rem;
}

.subscriber-container {
  margin: auto;
}
