$white: #fff;
$black: #000;
$grey: #e6e6e6;
$light-grey: #f9fafb;
$dark-grey: #777;
$green: rgb(76, 175, 80);
$box-shadow: 0 2px 15px 0 rgba(210, 214, 220, 0.3);
$border: 1px solid $grey;
$border-radius: 4px;
$gold: #bf9352;

.subscribe-container {
  display: flex;
  flex-direction: column;
  font-family: "Open Sans", sans-serif;
  font-size: 16px;
  line-height: 1.5;
  margin-bottom: 2rem;
  max-width: 600px;
  width: 100%;

  &,
  & * {
    box-sizing: border-box;
  }

  form {
    margin-bottom: 0;
  }

  &__title {
    color: $black;
    display: inline-block;
    font-size: 1.5rem;
    line-height: 1.2;
    margin-bottom: 0.5rem;
  }

  &__subtitle {
    font-size: 1rem;
    color: $dark-grey;
    padding-bottom: 0;
    margin-block-start: 0;
    margin-bottom: 1rem;
  }

  &__email {
    border-color: $grey;
    border-radius: $border-radius;
    color: rgb(0, 0, 0);
    font-weight: 400;
    width: 100%;
    background: $white;
    font-size: 15px;
    padding: 12px;
    border: $border;
    flex: 1 0 auto;
    line-height: 1.4;
    margin: 0;
    transition: border-color 0.3s ease-out;
    overflow: visible;
    margin-bottom: 1rem;
    font-family: "Open Sans", sans-serif;
  }

  &__submit {
    background-color: $gold;
    border-radius: 8px;
    color: $white;
    cursor: pointer;
    font-size: 1.2rem;
    font-weight: 700;
    transition: background-color 0.3s ease-in-out;
    padding: 12px 18px;
    width: 100%;
    font-family: "Open Sans", sans-serif;

    &:hover {
      background-color: darken($gold, 10%);
    }
  }
}
